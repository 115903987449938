.webchat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 800px;
    padding:10px;
}

.webchat {
    border: 1px solid #ccc;
    background-color: #fff;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 100%;
    box-shadow: 0px 0 10px 0px #bbb;
    width:800px !important;
    height:100% !important;  
    border-radius: 5px;
}


.left-bar{
    display: block;
    height: 100%;
    padding:10px;
}

.left-bar-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0 10px 0px #bbb;
    border: 1px solid #bbb;
}

.left-bar-element {
    display:block;
    width:auto;
    padding:5px;
    margin-bottom:5px;
    min-height:40px;
}

.webchat-logo {
    width:50px;
    height:50px;
    border-radius:35px;
    display:block;
    cursor:pointer;
}


.logout-button {
    background-color: #2388FF;
    padding: 5px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.logout-button:hover {
    background-color: #2388FF;
}